import {
  Avatar,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Icon,
  Text
} from '@chakra-ui/react';
import { useAuth } from 'context';

import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { PropsWithChildren } from 'react';
import { FcUpRight } from 'react-icons/fc';
import { showLoginModal, useLayoutContext } from 'context/layout';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'hooks';

export type NextChakraButtonProps = PropsWithChildren<
  NextLinkProps & Omit<ChakraButtonProps, 'as'>
>;

export const AuthBtn: React.FC = ({
  isOpen,
  setIsOpen
}: NextChakraButtonProps) => {
  const { t } = useTranslation();
  const { mutateLogout, isLoggedIn, token }: any = useAuth();
  const { dispatch, state } = useLayoutContext();

  const { data: session, status } = useSession();

  return (
    <ChakraButton
      as={ChakraButton}
      passHref={true}
      leftIcon={<Icon fontSize={'lg'} as={FcUpRight} />}
      display={'inline-flex'}
      textAlign={{ base: 'center', xl: 'start' }}
      justifyContent={{ base: 'center', xl: 'flex-start' }}
      borderRadius={'3xl'}
      variant='unstyled'
      fontWeight={'normal'}
      color={'dark.600'}
      bgColor={'transparent'}
      fontSize={'lg'}
      pr={{ base: 4, lg: 2, xl: 2 }}
      pl={{ base: 4, lg: 2, xl: 2 }}
      onClick={() => {
        if (session) {
          mutateLogout();
        } else {
          dispatch(showLoginModal());
        }
      }}
      _hover={{
        bgColor: 'light.500'
      }}
      cursor='pointer'
    >
      <Text as='span' display={{ md: 'inline', lg: 'none', xl: 'inline' }}>
        {session ? t('general.forms.logout') : t('general.forms.login')}
      </Text>
    </ChakraButton>
  );
};
