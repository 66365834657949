import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  VStack,
  Icon,
  Flex,
  Box
} from '@chakra-ui/react';

import { MenuItem } from './MenuItem';
import { ArrowLeft2 } from 'iconsax-react';
export const MenuDrawer = ({ isOpen, onToggle, items, title }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onToggle}
      closeOnEsc={true}
      closeOnOverlayClick={true}
    >
      <DrawerOverlay />
      <DrawerContent p='1'>
        <DrawerHeader>
          <Flex>
            <Box>{title}</Box>
            <Icon
              as={ArrowLeft2}
              onClick={onToggle}
              fontSize='28px'
              mt='1'
              ml='2'
              cursor={'pointer'}
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#BBc0c4',
              borderRadius: '24px'
            }
          }}
        >
          <VStack
            align={'stretch'}
            ml={2}
            pl='2'
            borderInlineStartWidth={2}
            borderInlineStartColor='light.500'
          >
            {Array.isArray(items) &&
              items?.map(item => (
                <Box key={item.id} onClick={onToggle}>
                  <MenuItem {...item} fontSize='md' isChild={true} />
                </Box>
              ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
